var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"col-sm-2 col-md-4 col-lg-3 col-xl-3"},[_c('div',{staticClass:"after_login"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":""}})]),(_vm.activeUser)?_c('a',{staticClass:"mobile_nav",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.toggleMobileNav(true)}}},[_c('span'),_c('span'),_c('span')]):_vm._e()],1),(_vm.activeUser)?_c('nav',{class:{
            mobile_navigation_open: _vm.mobileNavOn,
            mobile_navigation_close: !_vm.mobileNavOn,
        }},[_c('a',{staticClass:"closenav",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.toggleMobileNav(false)}}},[_vm._v("X")]),_c('ul',[_vm._l((_vm.navigations),function(item,index){return _c('li',{key:index},[_c('router-link',{class:{
                        active: _vm.presentRouteName.indexOf(item.name) >= 0,
                        disabled:
                            (_vm.activeUser.member_status !== 'approved' &&
                                item.requiresVerify) ||
                            item.forcedDisabled ||
                            _vm.checkIfFromBannedNation,
                    },attrs:{"to":item.url},nativeOn:{"click":function($event){return _vm.toggleMobileNav(false, item)}}},[_c('img',{staticClass:"iconnormal",attrs:{"src":_vm.getImgUrl(item.normalIcon),"alt":""}}),_c('img',{staticClass:"iconhover",attrs:{"src":_vm.getImgUrl(item.hoverIcon),"alt":""}}),_vm._v(" "+_vm._s(item.slug)+" "),(
                            item.badge &&
                            _vm.activeUser.num_unread_notifications > 0
                        )?_c('span',[_vm._v(_vm._s(_vm.activeUser.num_unread_notifications))]):_vm._e()])],1)}),_c('li',[(_vm.activeUser)?_c('a',{attrs:{"href":("preferences/" + (_vm.activeUser.member_id)),"target":"_blank"}},[_c('img',{staticClass:"iconnormal",attrs:{"src":require("@/assets/images/icons/pref_center.png"),"alt":""}}),_c('img',{staticClass:"iconhover",attrs:{"src":require("@/assets/images/icons/pref_center_hover.png"),"alt":""}}),_vm._v(" Preferences ")]):_vm._e()]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('img',{staticClass:"iconnormal",attrs:{"src":require("@/assets/images/icons/logout.png"),"alt":""}}),_c('img',{staticClass:"iconhover",attrs:{"src":require("@/assets/images/icons/logout_hover.png"),"alt":""}}),_vm._v(" Logout ")])])],2),(_vm.stateLoaded)?_c('div',{staticClass:"user_card_bottom"},[_c('div',{staticClass:"img_block",on:{"click":_vm.showProfileImgaeUpload}},[_c('img',{attrs:{"src":_vm.activeUser.profile_image,"alt":""}})]),_c('div',{staticClass:"contentblock",staticStyle:{"cursor":"pointer"},on:{"click":_vm.goToProfile}},[_c('div',{staticClass:"info"},[_c('h3',[_vm._v(" "+_vm._s(_vm.activeUser.first_name)+" "+_vm._s(_vm.activeUser.last_name)+" ")]),_c('span',[_vm._v("Player ID: "+_vm._s(_vm.activeUser.player_id))]),(_vm.remainingDays)?_c('span',[_vm._v(_vm._s(_vm.remainingDays))]):_vm._e()]),_c('div',{staticClass:"tag"},[_vm._v(" "+_vm._s(_vm.activeUser.membership_level ? _vm.activeUser.membership_level.name : '')+" ")])])]):_vm._e()]):_vm._e(),_c('ProfilePhotoModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }