<template>
    <div class="container-fluid">
        <div class="row">
            <sidebar />
            <main class="col-sm-10 col-md-8 col-lg-9 col-xl-9">
                <slot />
            </main>
        </div>
    </div>
</template>

<script
    src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
    integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj"
    crossorigin="anonymous"
></script>
<script>
import Sidebar from '@/components/sidebar/Sidebar'
export default {
    components: {
        Sidebar,
    },
    data() {
        return {}
    },
}
</script>

<style></style>
