import axios from 'axios'
import router from './router'
import helper from './helper'

let config = {
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
        'App-Secret': process.env.VUE_APP_SECRET,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        withCredentials: true,
    },
    timeout: 100000,
}
const httpClient = axios.create(config)

const authInterceptor = (config) => {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    helper.showLoading();
    return config
}

const loggerInterceptor = (config) => {
    /** TODO */
    return config
}

httpClient.interceptors.request.use(authInterceptor)
httpClient.interceptors.request.use(loggerInterceptor)

httpClient.interceptors.response.use(
    (response) => {
        helper.hideLoading();
        if (response.data.status){
          return response
        }
        else if (response.data.errors && !response.data.status) {
            response.data.errors.forEach((err) => {
                helper.showMessage(err, 'danger')
            })
            return Promise.reject()
        }
    },
    (error) => {
        helper.hideLoading();
        if (error.response.status === 403) {
            console.log('unauthorized, logging out ...')
            router.replace('/')
        }
        return Promise.reject(error)
    }
)

export { httpClient }
