<template>
    <header class="col-sm-2 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/" class="logo"
            ><img src="@/assets/images/logo.svg" alt=""
        /></router-link>
        <!--Login -->
        <div class="login_block">
            <h3>Enter your login details to access your account</h3>
            <form action="" @submit.stop.prevent="onSubmit">
                <div class="shadow_block">
                    <a href="#" class="forgot_psw" @click="showFPModal"
                        >Forgot</a
                    >
                    <div class="form-group">
                        <b-form-input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            v-model="$v.form.email.$model"
                            :state="validateState('email')"
                            aria-describedby="emailHelp"
                        ></b-form-input>
                        <b-form-invalid-feedback id="emailHelp"
                            >Please Enter a valid email</b-form-invalid-feedback
                        >
                    </div>
                    <div class="form-group">
                        <b-form-input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            v-model="$v.form.password.$model"
                            :state="validateState('password')"
                            aria-describedby="passwordHelp"
                        ></b-form-input>
                        <b-form-invalid-feedback id="passwordHelp"
                            >Password is required</b-form-invalid-feedback
                        >
                    </div>
                    <div class="form-group mt-2">
                        <vue-recaptcha
                            :sitekey="envSiteKey"
                            :loadRecaptchaScript="true"
                            @verify="getCaptcha"
                        ></vue-recaptcha>
                    </div>
                </div>
                <button type="submit" class="btn">Log In</button>
            </form>
        </div>
        <!--End Login -->
        <hr />
        <!--App Button Box -->
        <div class="appbutton_box">
            <p>
                Download and install the Grand Casino mobile app to enjoy app-specific offers and much more.
            </p>
            <div class="buttonbox">
                <a
                    :href="ios_app_install_link"
                    target="_blank"
                >
                    <img src="@/assets/images/apple.svg" alt="" />
                </a>
                <a 
                    :href="android_app_install_link"
                    target="_blank"
                >
                    <img src="@/assets/images/android.svg" alt="" />
                </a>
            </div>
        </div>
        <forgot-password />
        <!--End App Button Box -->
        <nav />
    </header>
</template>

<script>
import { BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import ForgotPassword from './ForgotPassword.vue'
import VueRecaptcha from 'vue-recaptcha'
export default {
    mixins: [validationMixin],
    components: { BFormInput, BFormInvalidFeedback, ForgotPassword, VueRecaptcha },
    created() {
        this.envSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY
        this.fetchConfig()
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
                recaptcha: '',
            },
            ios_app_install_link: null,
            android_app_install_link: null,
            envSiteKey: '',
        }
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            password: {
                required,
            },
            recaptcha: {
                required,
            },
        },
    },
    methods: {
        validateState(name) {
            return this.$helper.validateState(name, this)
        },
        async fetchConfig() {
            try {
                const resp = await this.$http.get('/config')
                this.ios_app_install_link =
                    resp.data.system_settings.length > 0
                        ? resp.data.system_settings[9]
                              .ios_app_install_link
                        : ''
                this.android_app_install_link =
                    resp.data.system_settings.length > 0 ? resp.data.system_settings[10].android_app_install_link : ''
            } catch (e) {
                console.error(e)
            }
        },
        showFPModal() {
            this.$bvModal.show('fpmodal')
        },
        async onSubmit() {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) {
                return
            }
            // this.$helper.showLoading();
            this.login_error = ''
            // await this.$http.get('/sanctum/csrf-cookie')
            this.$store
                .dispatch('auth/login', {
                    ...this.form,
                    device_type: 'W',
                    device_token: `WEBAPP-${this.moment().unix()}`,
                })
                .then((res) => {
                    // this.$helper.hideLoading();
                    if (res && res.status === false) {
                        console.log('logged in')
                    }
                })
                .catch((err) => {
                    console.error('login error', err)
                })
        },
        getCaptcha(res) {
            this.$v.form.recaptcha.$model = res
        },
    },
}
</script>
