export default [
    {
        slug: 'Home',
        name: 'home',
        url: '/home',
        normalIcon: 'home.png',
        hoverIcon: 'home_hover.png',
        requiresVerify: false,
        forcedDisabled: false,
    },
    {
        slug: 'Experiences',
        name: 'experiences',
        url: '/experiences',
        normalIcon: 'calendar.png',
        hoverIcon: 'calendar_hover.png',
        requiresVerify: false,
        forcedDisabled: false,
    },
    {
        slug: 'Notifications',
        name: 'notifications',
        url: '/notifications',
        normalIcon: 'notification.png',
        hoverIcon: 'notification_hover.png',
        badge: true,
        requiresVerify: true,
        forcedDisabled: false,
    },
    {
        slug: 'Food and Drink Menus',
        name: 'foodsDrinks',
        url: '',
        otherUrl: 'https://grandcasino.co.nz/grand-experiences/dining/',
        normalIcon: 'spoon.png',
        hoverIcon: 'spoon_hover.png',
        requiresVerify: true,
        forcedDisabled: false,
    },
    {
        slug: 'Table Reservations',
        name: 'tableReservations',
        url: '',
        otherUrl: 'mailto:enquiries@grandcasino.co.nz?subject=Restaurant%20reservation%20request&bcc=mircot@grandcasino.co.nz&bcc=lydiap@grandcasino.co.nz',
        normalIcon: 'reservation.png',
        hoverIcon: 'reservation_hover.png',
        requiresVerify: true,
        forcedDisabled: false,
    },
    {
        slug: 'Hotel Booking Request',
        name: 'hotelBoookingRequests',
        url: '/hotel-bookings',
        normalIcon: 'hotel.png',
        hoverIcon: 'hotel_hover.png',
        requiresVerify: true,
        forcedDisabled: false,
    },
    // {
    //     slug: 'Order History',
    //     name: 'orderHistory',
    //     url: '/order-history',
    //     normalIcon: 'order.png',
    //     hoverIcon: 'order_hover.png',
    //     requiresVerify: true,
    //     forcedDisabled: true,
    // },
    {
        slug: 'Offer History',
        name: 'offerHistory',
        url: '/offer-history',
        normalIcon: 'offer.png',
        hoverIcon: 'offer_hover.png',
        requiresVerify: true,
        forcedDisabled: false,
    },
    {
        slug: 'Settings',
        name: 'settings',
        url: '/settings',
        normalIcon: 'setting.png',
        hoverIcon: 'setting_hover.png',
        requiresVerify: true,
        forcedDisabled: false,
    },
    {
        slug: 'My Profile',
        name: 'profile',
        url: '/profile',
        normalIcon: 'user.png',
        hoverIcon: 'user_hover.png',
        requiresVerify: false,
        forcedDisabled: false,
    },
]
