<template>
    <div id="app">
        <!-- <component :is="layout">
      <router-view :layout.sync="layout" />
    </component> -->
        <component :is="this.$route.meta.layout || 'div'">
            <vue-page-transition name="fade-in-up">
                <router-view />
            </vue-page-transition>
        </component>
    </div>
</template>

<script>
import NoAuthLayout from './layouts/NoAuthLayout'
import AuthLayout from './layouts/AuthLayout'
export default {
    components: {
        NoAuthLayout,
        AuthLayout,
    },
    data() {
        return {
            layout: `div`,
        }
    },
}
</script>
<style>
@import './assets/css/custom.css';
/* @import "./assets/css/bootstrap.css"; */
</style>
