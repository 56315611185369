import context from './main.js'
export default {
  showLoading() {
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "block";
    }
  },
  hideLoading() {
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "none";
    }
  },
  checkUserPermission(action, slug, permissions) {
    if (slug === "dashboard") {
      return true;
    }
    const requiredPermission = `${slug}-${action}`;
    return permissions.indexOf(requiredPermission) >= 0;
  },
  ucFirst(str) {
    return str[0].toUpperCase() + str.slice(1);
  },
  validateState(name, vm) {
    const { $dirty, $error } = vm.$v.form[name];
    return $dirty ? !$error : null;
  },
  showMessage(message, variant = "success"){
    const vm = context;
    const h = vm.$createElement;
    const vNodesMsg = h("p",
      { class: ["text-center", "mb-0"] },
      [
        `${message} `,
      ]
    )
    const vNodesTitle = h(
      "div",
      { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
      [
        h("strong", { class: "mr-2" }, variant === `success` ? "Success" : "Error"),
      ]
    )
    // Pass the VNodes as an array for message and title
    vm.$bvToast.toast([vNodesMsg], {
      title: [vNodesTitle],
      solid: true,
      variant: variant,
      toaster: "b-toaster-top-center",
    });
  }
};
