import Vue from 'vue'
import VueRouter from 'vue-router'
// import ga from 'vue-ga'
import store from '../store'
import VueGtag from 'vue-gtag'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/authentication/Home.vue'),
        meta: { layout: 'NoAuthLayout', guest: true },
    },
    {
        path: '/reset-password',
        name: 'resetPassword',
        component: () => import('@/views/authentication/ResetPassword.vue'),
        meta: { layout: 'div', guest: true },
    },
    {
        path: '/signup',
        name: 'signUp',
        component: () => import('@/views/authentication/Signup.vue'),
        meta: { layout: 'NoAuthLayout', guest: true },
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/dashboard/Home'),
        meta: { layout: 'AuthLayout', requiresAuth: true },
    },
    {
        path: '/experiences',
        name: 'experiences',
        component: () => import('@/views/dashboard/Experiences'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: false,
        },
    },
    {
        path: '/experience-calender',
        name: 'experiences_calender',
        component: () => import('@/views/dashboard/ExperienceCalenders'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: false,
        },
    },
    {
        path: '/my-events',
        name: 'experiences_joined',
        component: () => import('@/views/dashboard/MyEvents'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: false,
        },
    },
    {
        path: '/experiences/:type/:id',
        name: 'experiences_details',
        component: () => import('@/views/dashboard/ExperienceDetails'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: false,
        },
        props: (route) => ({
            ...route.params,
        }),
    },
    {
        path: '/offers/:id',
        name: 'offer_details',
        component: () => import('@/views/dashboard/OfferHistory/Details'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: false,
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/dashboard/Settings'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: false,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/dashboard/Profile'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: false,
        },
    },
    {
        path: '/hotel-bookings',
        name: 'hotelBoookingRequests',
        component: () => import('@/views/dashboard/HotelBooking'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/offer-history',
        name: 'offerHistory',
        component: () => import('@/views/dashboard/OfferHistory'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/views/dashboard/Notifications'),
        meta: {
            layout: 'AuthLayout',
            requiresAuth: true,
            requiresVerify: true,
        },
    },
    {
        path: '/preferences/:id',
        name: 'Preferences',
        component: () => import('@/views/pref'),
        meta: { layout: 'div' },
    },
    // {
    //     path: '/new-preferences/:id',
    //     name: 'Preferences',
    //     component: () => import('@/views/pref'),
    //     meta: { layout: 'div' },
    // },
    {
        path: '*',
        name: '404',
        component: () => import('@/views/errors/404.vue'),
        meta: { layout: 'div', guest: true, requiresAuth: true },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})
// ga(router, 'G-R2YD33XTX1')
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') == null) {
            next({
                path: '/',
                params: { nextUrl: to.fullPath },
            })
        } else {
            if (to.matched.some((record) => record.meta.requiresVerify)) {
                if (
                    store.getters['auth/getAuthenticatedUser'].member_status !==
                    'approved'
                ) {
                    next({
                        path: '/home',
                    })
                } else {
                    next()
                }
            }
            next()
        }
    } else if (to.matched.some((record) => record.meta.guest)) {
        if (localStorage.getItem('token') == null) {
            next()
        } else {
            next({ path: 'home' })
        }
    } else {
        next()
    }
})

router.afterEach(() => {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

Vue.use(
    VueGtag,
    {
        config: { id: 'G-R2YD33XTX1' },
    },
    router
)
export default router
