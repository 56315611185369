<template>
    <header class="col-sm-2 col-md-4 col-lg-3 col-xl-3">
        <div class="after_login">
            <router-link to="/" class="logo"
                ><img src="@/assets/images/logo.svg" alt=""
            /></router-link>

            <a
                href="javascript:void(0);"
                class="mobile_nav"
                v-if="activeUser"
                @click="toggleMobileNav(true)"
            >
                <span></span>
                <span></span>
                <span></span>
            </a>
        </div>

        <nav
            v-if="activeUser"
            :class="{
                mobile_navigation_open: mobileNavOn,
                mobile_navigation_close: !mobileNavOn,
            }"
        >
            <a
                href="javascript:void(0);"
                class="closenav"
                @click="toggleMobileNav(false)"
                >X</a
            >
            <ul>
                <li v-for="(item, index) of navigations" :key="index">
                    <router-link
                        :to="item.url"
                        @click.native="toggleMobileNav(false, item)"
                        :class="{
                            active: presentRouteName.indexOf(item.name) >= 0,
                            disabled:
                                (activeUser.member_status !== 'approved' &&
                                    item.requiresVerify) ||
                                item.forcedDisabled ||
                                checkIfFromBannedNation,
                        }"
                    >
                        <img
                            :src="getImgUrl(item.normalIcon)"
                            alt=""
                            class="iconnormal"
                        />
                        <img
                            :src="getImgUrl(item.hoverIcon)"
                            alt=""
                            class="iconhover"
                        />
                        {{ item.slug }}
                        <span
                            v-if="
                                item.badge &&
                                activeUser.num_unread_notifications > 0
                            "
                            >{{ activeUser.num_unread_notifications }}</span
                        >
                    </router-link>
                </li>
                <li>
                    <a
                        v-if="activeUser"
                        :href="`preferences/${activeUser.member_id}`"
                        target="_blank"
                    >
                        <img
                            src="@/assets/images/icons/pref_center.png"
                            alt=""
                            class="iconnormal"
                        />
                        <img
                            src="@/assets/images/icons/pref_center_hover.png"
                            alt=""
                            class="iconhover"   
                        />
                        Preferences
                    </a>
                </li>
                <li>
                    <a href="#" @click.prevent="logout">
                        <img
                            src="@/assets/images/icons/logout.png"
                            alt=""
                            class="iconnormal"
                        />
                        <img
                            src="@/assets/images/icons/logout_hover.png"
                            alt=""
                            class="iconhover"
                        />
                        Logout
                    </a>
                </li>
            </ul>

            <!--User Card -->
            <div class="user_card_bottom" v-if="stateLoaded">
                <div class="img_block" @click="showProfileImgaeUpload">
                    <img :src="activeUser.profile_image" alt="" />
                </div>
                <div
                    class="contentblock"
                    @click="goToProfile"
                    style="cursor: pointer"
                >
                    <div class="info">
                        <h3>
                            {{ activeUser.first_name }}
                            {{ activeUser.last_name }}
                        </h3>
                        <span>Player ID: {{ activeUser.player_id }}</span>
                        <span v-if="remainingDays">{{ remainingDays }}</span>
                    </div>
                    <div class="tag">
                        {{
                            activeUser.membership_level
                                ? activeUser.membership_level.name
                                : ''
                        }}
                    </div>
                </div>
            </div>
            <!--End user Card -->
        </nav>
        <ProfilePhotoModal />
    </header>
</template>

<script>
import Navigations from './Navigation.js'
import ProfilePhotoModal from '../profile_photo_modal'
export default {
    components: { ProfilePhotoModal },
    data() {
        return {
            navigations: Navigations,
            stateLoaded: false,
            mobileNavOn: false,
            remainingDays: null,
        }
    },
    computed: {
        activeUser() {
            if (this.$store.state.auth.user) return this.$store.state.auth.user
            else return null
        },
        presentRouteName() {
            return this.$route.name
        },
        isReviewPending() {
            if (this.activeUser) {
                return this.activeUser.member_status === 'pending_review'
            } else return false
        },
        checkIfFromBannedNation() {
            if (this.activeUser) {
                return (
                    this.isReviewPending &&
                    this.activeUser.country &&
                    this.activeUser.country.is_review_needed === 1
                )
            } else return false
        },
    },
    created() {
        this.remainingDaysText()
        setTimeout(() => (this.stateLoaded = true), 1000)
    },
    methods: {
        showProfileImgaeUpload() {
            this.$bvModal.show('profilemodal')
        },
        getImgUrl(assetPath) {
            return require('@/assets/images/icons/' + assetPath)
        },
        logout() {
            this.$store
                .dispatch('auth/logout')
                .then(() => {
                    this.$router.push({ path: '/' })
                })
                .catch((err) => {
                    console.error('login error', err)
                })
        },
        toggleMobileNav(val, item = null) {
            this.mobileNavOn = val
            if (item && 'otherUrl' in item) {
                window.open(item.otherUrl, '_blank')
            }
        },
        goToProfile() {
            if (this.activeUser.member_status !== 'approved') {
                return false
            }
            if (this.$route.path !== '/profile') {
                this.$router.push({ path: '/profile' })
            }
        },
        async fetchConfig() {
            try {
                const resp = await this.$http.get('/config')
                return resp.data.membership_quarters || []
            } catch (e) {
                return []
            }
        },
        remainingDaysText() {
            this.$http.get('/config').then((resp) => {
                const quarters = resp.data.membership_quarters || []
                const currDate = this.moment()
                let nextReviewDate = null
                quarters.forEach((quarter) => {
                    let st_dt = this.moment(
                        new Date().getFullYear() + '-' + quarter.start_date,
                        'YYYY-M-D'
                    )
                    let end_dt = this.moment(
                        new Date().getFullYear() + '-' + quarter.end_date,
                        'YYYY-M-D'
                    )

                    if (end_dt < st_dt) {
                        // recaculate the year
                        end_dt = this.moment(
                            new Date().getFullYear() +
                                1 +
                                '-' +
                                quarter.end_date,
                            'YYYY-M-D'
                        )
                    }
                    if (currDate >= st_dt && currDate <= end_dt) {
                        nextReviewDate = end_dt
                    }
                })
                const dateDiff = nextReviewDate.diff(currDate, 'days')
                let txt = `${dateDiff} day`
                if (dateDiff > 1) {
                    txt += 's'
                }
                txt += ' to the end of the next Tier change date'
                this.remainingDays = txt
            })
        },
    },
}
</script>
<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
.mobile_navigation_open {
    left: 0;
    transition: all 0.5s;
}
.mobile_navigation_close {
    left: -1200px;
}
</style>
