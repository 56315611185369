import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMask from 'v-mask'
Vue.use(VueMask)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import moment from 'moment'
Vue.prototype.moment = moment

import lodash from 'lodash'
Vue.prototype._ = lodash

import { httpClient } from './axios.js'
Vue.prototype.$http = httpClient

import helper from './helper'
Vue.prototype.$helper = helper

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import OtpInput from '@bachdgvn/vue-otp-input'

Vue.component('v-otp-input', OtpInput)

import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

Vue.config.productionTip = false
require('@/store/subscriber')

const vue = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')

export default vue
