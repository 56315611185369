<template>
  <div class="container-fluid">
    <div class="row vh-100">
      <login />
      <main class="col-sm-10 col-md-8 col-lg-9 col-xl-9">
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import Login from "@/views/authentication/Login";
export default {
  components: {
    Login,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
