<template>
    <b-modal
        id="fpmodal"
        v-model="displayModal"
        hide-footer
        hide-header
        body-class="p-0"
    >
        <div class="modal-content">
            <div class="modal-header">
                <button
                    @click="hideModal"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="verification_block">
                    <h2>Reset Password</h2>
                    <p>
                        Enter your registered email or mobile number
                    </p>
                    <div class="block">
                        <div class="login_block">
                            <div class="shadow_block">
                                <div class="form-group">
                                    <b-form-input
                                        type="text"
                                        id="email_or_number"
                                        name="email_or_number"
                                        placeholder="Email / Mobile Number"
                                        v-model="EmailOrPhone"
                                        aria-describedby="emailHelp"
                                        autocomplete="off"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                        id="passwordHelp"
                                        :state="isValid"
                                        >{{
                                            errorMessage
                                        }}</b-form-invalid-feedback
                                    >
                                </div>
                            </div>
                            <div class="verify-block">
                                <vue-recaptcha
                                    :sitekey="envSiteKey"
                                    :loadRecaptchaScript="true"
                                    @verify="getCaptcha"
                                ></vue-recaptcha>
                            </div>
                        </div>
                        <button @click="onSubmit" class="btn">
                            Reset Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
    name: 'forgot-password',
    components: { VueRecaptcha },
    props: {
        modalShow: {
            default: false,
        },
    },
    data() {
        return {
            displayModal: false,
            EmailOrPhone: '',
            errorMessage: 'Invalid Input',
            isValid: true,
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, // eslint-disable-line
            envSiteKey: '',
            reCaptcha: '',
        }
    },
    created() {
        this.envSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY
        // console.log('env check in modal', this.envSiteKey)
    },
    methods: {
        hideModal() {
            this.displayModal = false
        },
        validate() {
            if (this.EmailOrPhone) {
                if (isNaN(this.EmailOrPhone)) {
                    let isEmail = this.validateEmail(this.EmailOrPhone)
                    if (!isEmail) {
                        this.errorMessage = 'Please enter a valid Email'
                        return false
                    }
                    return true
                } else {
                    if (this.EmailOrPhone.length < 8) {
                        this.errorMessage = 'Enter a valid Mobile Number'
                        return false
                    }
                    return true
                }
            }
            return false
        },
        validateEmail(email) {
            const re = this.reg
            return re.test(email)
        },
        onSubmit() {
            this.isValid = this.validate()
            if (this.isValid) {
                this.$http
                    .post('forgot-password', {
                        email: this.EmailOrPhone,
                        recaptcha: this.reCaptcha,
                        device_type: 'W',
                    })
                    .then((resp) => {
                        if (resp.data.status) {
                            this.$helper.showMessage(resp.data.message)
                            console.error(resp.data)
                            this.hideModal();
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }
        },
        getCaptcha(res) {
            this.reCaptcha = res
        },
    },
}
</script>
<style scoped>
.login_block .shadow_block {
    margin: 0px;
}
.verify-block {
    margin: 1rem 0 3.3rem 0;
}
</style>
