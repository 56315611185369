export default{
    props:{
        modalShow: {
            default: false
        }
    },
    data(){
        return {
            displayModal: false,
            files: [],
            fileError: null,
            acceptedMimeType: ["image/jpeg", "image/png"],
        }
    },
    created(){
        this.displayModal = this.modalShow;
    },
    methods:{
        hideModal() {
           this.displayModal = false;
        },
        openFileSelector() {
            document.getElementById('fileUpload').click();
            this.fileError = null;
        },
        validate() {
            if(this.files.length > 1) {
                this.fileError = "You can choose maximum 1 file";
                return false;
            }
            for(let i=0; i<this.files.length; i++) {
                if(this.acceptedMimeType.indexOf(this.files[i].type) < 0){
                    this.fileError = "Please choose JPEG/PNG Files";
                    return false;
                }
            }
            return true;
        },
        async upload(){
            this.files = this.$refs.files.files;
            const validation = this.validate();
            if(validation) {
                let formData = new FormData();
                formData.append
                for(let i=0; i<this.files.length; i++){
                    formData.append('document_type', 'profile_image');
                    formData.append('file_name', this.files[i]);
                }
                try {
                    const response = await this.$http.post('/upload-member_documents', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    });
                    if(response.data.status) {
                        this.$emit('uploaded');
                        this.hideModal();
                        this.$helper.showMessage(response.data.message);
                        this.$store.dispatch('auth/attempt', this.$store.state.token);
                    }
                }catch(error) {
                    console.log(error);
                }
            }
        },
    },
    computed: {
        activeUser() {
            return this.$store.getters['auth/getAuthenticatedUser'];
        }
    },
}
