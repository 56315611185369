import { httpClient } from '@/axios.js'
import router from "@/router/index.js";
export default {
    namespaced: true,
    preserveState: true,
    state: {
        token: null,
        user: null,
        impersonate: false,
        exp_data: null,
    },
    actions: {
        async login({ dispatch }, credentials) {
            try {
                const response = await httpClient.post('/login', credentials)
                if (response.data.status === false) {
                    return response.data
                }
                if (response.status === 401) {
                    throw new Error('Invalid Credentials')
                    // return response.data;
                }

                dispatch('attempt', response.data.access_token)
                router.push(router.currentRoute.query.to || '/home')
                return true
            } catch (err) {
                throw new Error('Invalid Credentials')
            }
        },

        async signup({ dispatch }, credentials) {
            try {
                const response = await httpClient.post( '/signup-web', credentials)
                if (response.data.status === false) {
                    return response.data
                }

                dispatch('attempt', response.data.access_token)
                router.push(router.currentRoute.query.to || '/home')
                return true
            } catch (err) {
                throw new Error('Something went wrong')
            }
        },

        async startImpersonate({ dispatch, state }, credentials) {
            const response = await httpClient.post('impersonate', credentials)
            if (response.data.status === false) {
                return response.data
            }

            localStorage.setItem('impersonate_token', state.token)
            dispatch('attempt', response.data.access_token)
            state.impersonate = true
            // router.push(router.currentRoute.query.to || '/account/dashboard')
            return true
        },

        async stopimpersonate({ dispatch, state }) {
            return httpClient.get('auth/logout').then(() => {
                const token = localStorage.getItem('impersonate_token')
                dispatch('attempt', token)
                state.impersonate = false
                // router.push(router.currentRoute.query.to || '/account/dashboard')
            })
        },

        async logout({ commit }) {
            return httpClient.get('/logout').then(() => {
                commit('SET_TOKEN', null)
                commit('UPDATE_USER', null)
            })
        },

        async attempt({ commit, state }, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }
            if (!state.token) {
                return
            }
            try {
                const response = await httpClient.post('get-member-data')
                commit('UPDATE_USER', response.data.response_data.member_detail)
            } catch (e) {
                console.error(e);
                commit('UPDATE_USER', null)
                commit('SET_TOKEN', null)
            }
        },

        async store_experiences( {commit}, data){
            if(data){
                commit('SET_EXP_DATA', data);
            }
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            if (token || token === null) {
                state.token = token
            }
        },

        UPDATE_USER(state, data) {
            state.user = data
        },

        SET_EXP_DATA(state, data) {
            state.exp_data = data
        },
    },
    getters: {
        isAuthenticated(state) {
            return state.token && state.user
        },
        getAuthenticatedUser(state) {
            return state.user
        },
        getAuthenticatedToken(state) {
            return state.token
        },
    },
}
