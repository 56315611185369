// import Vue from "vue";
// import Vuex from "vuex";

// Vue.use(Vuex);

// export default new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import VuexPersist from 'vuex-persist'
import auth from "./auth"

const vuexLocalStorage = new VuexPersist({
  key: 'webapp-casino',
  storage: window.localStorage,
})
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
  },
  plugins: [vuexLocalStorage.plugin],
  strict: process.env.DEV,
})
